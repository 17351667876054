const About = () => (
  <section className="p-8 h-full bg-gray-50 rounded-lg shadow-md max-w-4xl mx-auto my-10 overflow-y-scroll">
    <h2 className="text-4xl font-semibold text-center text-indigo-600 mb-6">
      About Us
    </h2>
    <div className="space-y-6 text-lg text-gray-700">
      <p>
        Welcome to <strong> The Mohalla Cafe </strong>! We bring the heart of
        traditional Indian flavors to your plate with a modern touch. Whether
        you're craving something comforting or looking for a healthier meal, our
        menu is designed to offer a variety of delicious options that cater to
        all tastes and preferences.
      </p>

      <p>
        Indulge in the crispy, buttery goodness of our{" "}
        <strong>Desi Parathas </strong> or the perfect cup of{" "}
        <strong>Chai</strong> to kickstart your day. Our{" "}
        <strong>Spicy Paneer </strong>
        is packed with bold flavors, and our <strong>
          Delicious Maggi
        </strong>{" "}
        brings back those nostalgic moments of comfort food with every bite. For
        those who prefer healthier options, we offer{" "}
        <strong>Healthy Meals</strong> made with fresh, wholesome ingredients.
        And don't miss our signature <strong>Tadka Rice </strong> that pairs
        beautifully with any dish!
      </p>

      <p>
        We take pride in the quality and authenticity of every dish we serve,
        ensuring that every bite is a true reflection of Indian culinary
        traditions. Our team is committed to providing excellent service, making
        sure that every visit to The Mohalla Cafe is a delightful experience.
      </p>

      <p>
        Planning a special event or gathering? We offer{" "}
        <strong>Catering Services </strong>
        on a call basis, bringing our flavorful dishes right to your doorstep.
        Whether it’s a family celebration, office party, or any other occasion,
        we’ll help make it a memorable event with our delicious food and
        personalized service.
      </p>

      <p className="text-center text-lg font-semibold text-indigo-600 mt-4">
        Join us at The Mohalla Cafe, where every meal feels like a celebration!
      </p>
    </div>
  </section>
);

export default About;
