import React from "react";

const ContactUs = () => (
  <div className="p-8 h-full bg-gray-50 rounded-lg shadow-md max-w-4xl mx-auto my-10 overflow-y-scroll">
    <h2 className="text-4xl font-extrabold mb-6 text-gray-800">Contact Us</h2>
    <div className="grid md:grid-cols-2 gap-8">
      {/* Contact Information */}
      <div>
        <h3 className="text-2xl font-semibold mb-4">Get in Touch</h3>
        <p className="mb-2">
          <strong>Phone:</strong>{" "}
          <a href="tel:07316980144" className="text-blue-500 hover:underline">
            07316980144
          </a>
        </p>
        <p className="mb-2">
          <strong>Instagram:</strong>{" "}
          <a
            href="https://www.instagram.com/themohallacafe/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            @themohallacafe
          </a>
        </p>
        <p className="mb-4">
          <strong>Location:</strong>{" "}
          <a
            href="https://g.co/kgs/JFQMfEr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Find us on Google Maps
          </a>
        </p>
        <p className="mb-4">
          <strong>Address:</strong>
          <br />
          Nagendra Nilaya, 6, Doddanekundi, Doddanekkundi
          <br />
          Bengaluru, Karnataka 560037
        </p>
      </div>

      {/* Correct Google Map */}
      <div className="overflow-hidden rounded-lg shadow-lg">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9917171399666!2d77.6932101!3d12.9723814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11002e5d77c3%3A0x6d656895896f48d2!2sThe%20Mohalla%20Cafe%20%40%20Doddanekundi!5e0!3m2!1sen!2sin!4v1737300984726!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          title="The Mohalla Cafe Location"
        ></iframe>
      </div>
    </div>
  </div>
);

export default ContactUs;
