import { useState } from "react";
import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu state on mobile
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Close the menu when a link is clicked
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <header className="bg-gray-900 text-white p-4 flex justify-between items-center">
      <div className="flex items-center">
        {/* Logo wrapped in Link, acting as home button */}
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-10 w-10 mr-2 rounded-full" />
          <h1 className="text-xl font-bold">The Mohalla Cafe</h1>
        </Link>
      </div>

      {/* Desktop Menu */}
      <nav className="hidden md:flex space-x-4">
        <Link to="/menu" className="hover:underline">
          Menu
        </Link>
        <Link to="/contact" className="hover:underline">
          Contact Us
        </Link>
        <Link to="/about" className="hover:underline">
          About
        </Link>
      </nav>

      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="absolute top-16 right-4 bg-gray-800 text-white rounded-lg shadow-lg md:hidden w-48 p-4 space-y-4 z-20">
          <Link
            to="/menu"
            className="block hover:underline"
            onClick={closeMenu} // Close menu on click
          >
            Menu
          </Link>
          <Link
            to="/contact"
            className="block hover:underline"
            onClick={closeMenu} // Close menu on click
          >
            Contact Us
          </Link>
          <Link
            to="/about"
            className="block hover:underline"
            onClick={closeMenu} // Close menu on click
          >
            About
          </Link>
        </div>
      )}
    </header>
  );
};

export default NavBar;
