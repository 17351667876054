import { useEffect, useState } from "react";
import Cafe1 from "../assets/images/cafe1.jpg";
import Cafe2 from "../assets/images/cafe2.jpg";
import Cafe3 from "../assets/images/cafe3.jpg";

const Carousel = () => {
  const images = [Cafe1, Cafe2, Cafe3];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="carousel w-full h-full flex overflow-hidden relative">
      <img
        src={images[currentIndex]}
        alt={`Cafe ${currentIndex + 1}`}
        className="w-full h-full object-cover transition-transform duration-1000 ease-in-out"
      />
    </div>
  );
};

export default Carousel;
