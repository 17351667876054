import menuPdf from "../../assets/menu.pdf";

const Menu = () => (
  <div className="p-2 sm:p-8 bg-gray-50 rounded-lg shadow-md max-w-6xl mx-auto my-10 w-full h-full">
    <h2 className="text-2xl font-bold mb-4">Our Menu</h2>
    <iframe
      src={menuPdf}
      title="Menu PDF"
      className="w-full h-[80%] border rounded-lg shadow-lg transform scale-90 md:scale-100"
    ></iframe>
  </div>
);

export default Menu;
