import { Link } from "react-router-dom";
import Carousel from "../Carousel";

type Props = {};

function Home({}: Props) {
  return (
    <div className="relative w-full h-screen">
      {/* Background Carousel Images */}
      <Carousel />

      {/* Content Overlay with Blur Effect */}
      <div className="absolute inset-0 bg-black bg-opacity-40 z-10">
        {/* Main Container with Flex Layout */}
        <div className="flex justify-end items-center text-white px-6 py-12 h-full">
          {/* Left Section - Welcome Text */}
          <div className="flex flex-col items-start max-w-xl backdrop-blur-md bg-black bg-opacity-50 p-6 rounded-lg space-y-6">
            <h1 className="text-2xl sm:text-5xl font-extrabold text-left">
              Welcome to The Mohalla Cafe!
            </h1>
            <p className="text-base sm:text-lg text-left">
              Enjoy authentic Indian flavors, carefully crafted to satisfy every
              craving. Whether you're here for a quick snack or a family feast,
              we promise an experience like no other.
            </p>
            <Link
              to="/menu"
              className="bg-gray-900 text-white py-3 px-8 rounded-lg text-base sm:text-lg hover:bg-gray-800 transition duration-300 ease-in-out"
            >
              Explore Our Menu
            </Link>
          </div>
        </div>

        {/* Footer Section with Blur Effect */}
        <div className="absolute bottom-0 w-full text-center text-white px-6 backdrop-blur-md bg-black bg-opacity-50 py-4">
          <p className="text-base sm:text-lg">
            Join us for a taste of India, right in your neighborhood! We're
            excited to serve you at The Mohalla Cafe.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
