import { createBrowserRouter } from "react-router-dom";

import Layout from "../Layout";
import Home from "../components/Home";
import Menu from "../components/Menu";
import ContactUs from "../components/ContactUs";
import About from "../components/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/menu", element: <Menu /> },
      { path: "/contact", element: <ContactUs /> },
      { path: "/about", element: <About /> },
    ],
  },
]);

export default router;
