import { Link } from "react-router-dom";

const Footer = () => {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  return (
    <footer className="bg-gray-800 text-white p-4 text-center text-base sm:text-lg">
      <nav>
        <Link
          to="/menu"
          className="mr-4 hover:underline"
          onClick={scrollToTop} // Scroll to top when clicked
        >
          Menu
        </Link>
        <Link
          to="/contact"
          className="mr-4 hover:underline"
          onClick={scrollToTop} // Scroll to top when clicked
        >
          Contact Us
        </Link>
        <Link
          to="/about"
          className="hover:underline"
          onClick={scrollToTop} // Scroll to top when clicked
        >
          About
        </Link>
      </nav>
      <p className="mt-2">&copy; 2025 The Mohalla Cafe. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
