import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

const Layout = () => {
  return (
    <div className="h-screen w-screen">
      <NavBar />
      <main className="h-full">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
